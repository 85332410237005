<template>
  <div class="listBox">
    <!-- <ul class="listList">
      <li v-for="(item, index) in listData" :key="index">
        <a class="clearfloat" target="_blank" :href="!item.is_link || item.is_link === 0 ? `${apiName}detail?id=${item.id}` : item.link_url">
          <div class="time fr">
            <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
            <p>{{dayjs(item.create_time).format('YYYY')}}</p>
          </div>
          <div class="inner single-line">
            {{item.title}}
          </div>
        </a>
      </li>
    </ul> -->

    <ul class="listDesList">
      <li v-for="(item, index) in listData" :key="index">
        <div class="listDesTitle single-line">
          <a target="_blank" :href="!item.is_link || item.is_link === 0 ? `${apiName}detail?id=${item.id}` : item.link_url">{{item.title}}</a>
        </div>
        <div class="listDesDes multi-line1">
          {{ item.des }}
        </div>
        <div class="listDesTime">
          {{ dayjs(item.create_time).format('YYYY-MM-DD') }}
        </div>
      </li>
    </ul>
    <div class="listPage">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
        @current-change="currentChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    gropId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      pageSize: 20,
      pageNum: 1,
      total: 0,
      listData: []
    }
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    }
  },
  mounted() {
    var _this = this
    _this.getList()
  },
  methods: {
    async getList() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.gropId, page: _this.pageNum, pageSize: _this.pageSize});
      _this.total = data.data.total
      // _this.listData = data.data.list;
      data.data.list.length && data.data.list.forEach(line => {
        let des = _this.setText(line.content)
        _this.listData.push({
          ...line,
          des: des
        })
      })
    },
    currentChange(val) {
      var _this = this
      _this.pageNum = val
      _this.getList()
    },
    // 提取纯文本
    setText(val) {
      if (val != null && val != "") {
        var re1 = new RegExp("<.+?>|&.+?;","g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = val.replace(re1,""); //执行替换成空字符
        msg = msg.replace(/\s/g,""); //去掉所有的空格（中文空格、英文空格都会被替换）
        msg = msg.replace(/[\r\n]/g,""); //去掉所有的换行符
        // return msg.substr(0, 100); //获取文本文字内容的前100个字符
        return msg;
      } else return ""
    },
  }
}
</script>